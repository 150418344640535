<template>
  <div class="conter voice-box">
    <button @click="translationStart">
      开始识别
    </button>
    <button @click="translationEnd">
      停止
    </button>
    <el-input
      v-model="voiceTxt"
      placeholder="识别内容"
    />
  </div>
</template>

<script>
import IatRecorder from './js/index.js';

export default {
  data () {
    return {
      voiceTxt: null,
      fixedTxt: null,
      iatRecorder: null
    };
  },
  methods: {
    translationStart () {
      console.log('开始听写');
      this.iatRecorder.start();
    },
    translationEnd () {
      // this.iatRecorder.onTextChange = (text) => {
      //   const inputText = text;
      //   this.searchData = inputText.substring(0, inputText.length - 1); // 文字处理，因为不知道为什么识别输出的后面都带‘。’，这个方法是去除字符串最后一位
      // };
      this.iatRecorder.stop();
    }
  },
  watch: {
    'iatRecorder.resultTextTemp': {
      handler (newVal, oldVal) {
        this.voiceTxt = newVal;
      }
    }
  },
  mounted () {
    this.iatRecorder = new IatRecorder({
      language: 'zh_cn',
      accent: 'mandarin',
      appId: '2e6469a4',
      onTextChange: function (text) {
        // 监听识别结果的变化
        // 3秒钟内没有说话，就自动关闭
        // if (text) {
        //   clearTimeout(times);
        //   times = setTimeout(() => {
        //     this.stop(); // voice.stop();
        //     fixedBox.style.display = 'none';
        //   }, 3000);
        // }
        ;
      }
    });// 小语种-中文方言-appId
  }
};
</script>
