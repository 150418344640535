<template>
  <div class="contert">
    <el-input
      v-model="voiceTxt"
      placeholder="语音内容"
    />
    <button @click="play">
      开始合成
    </button>
    <button @click="pause">
      停止播放
    </button>
  </div>
</template>

<script>
import TtsRecorder from './js/audio';

const ttsRecorder = new TtsRecorder();
export default {
  data () {
    return {
      voiceTxt: '智慧消防是一种先进的解决方案，与传统消防相比，注重打通各系统间的信息孤岛、提升感知预警能力和应急指挥智慧能力。通过更早发现、更快处理，将火灾风险和影响降到最低。'
    };
  },
  methods: {
    play () {
      // 要合成的文本
      ttsRecorder.setParams({
        // 文本内容
        text: this.voiceTxt,
        // 角色
        //  voiceName: '',
        // 语速
        speed: 50,
        // 音量
        voice: 50
      });
      ttsRecorder.start();
    },
    pause () {
      ttsRecorder.stop();
    }
  }
};
</script>
